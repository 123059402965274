<template>
    <div class="Projectcation" style="margin-top:20px;">
        <div class="Project_select">
            <!-- <span>选择院系</span>
            <el-select v-model="depart" placeholder="请选择院系" size="small" @change="departs(depart)" style="margin-right:10px">
                <el-option
                    v-for="item in departList"
                    :key="item.yuanId"
                    :label="item.yuanName"
                    :value="item.yuanId">
                </el-option>
            </el-select> -->
            <span>选择批次</span>
            <el-select v-model="value" placeholder="请选择批次" size="small" @change="yearChange(value)" style="margin-right:10px">
                <el-option
                    v-for="item in listYear"
                    :key="item.value"
                    :label="item.value + '年'"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" size="mini">同步至 "互联网+" 项目平台</el-button>
            <el-button type="primary" size="mini" @click="experts()">选择专家评审</el-button>
        </div>
        <el-table 
            :data="tableData" 
            style="width: 100%;height:97%"
            stripe
            @selection-change="handleSelectionChange"
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="selection" :selectable="selectable" label="选择" width="55"></el-table-column>
            <!-- <el-table-column prop="Itemno" label="项目编号" width="150px"></el-table-column> -->
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column prop="type" label="项目类型" width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.type==1?'创新训练项目':scope.row.type==2?'创业训练项目':scope.row.type==3?'创业实践项目':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="priname" label="项目负责人" width="120px"></el-table-column>
            <el-table-column prop="monber" label="项目其他成员"></el-table-column>
            <el-table-column prop="tname" label="指导教师"></el-table-column>
            <!-- <el-table-column prop="rank" label="级别" width="100px">
                <template slot-scope="scope">
                    <span>{{scope.row.rank==1?'校级':scope.row.rank==2?'省级':scope.row.rank==3?'国家级':''}}</span>
                </template>
            </el-table-column> -->
            <el-table-column prop="tname" label="状态">
                <template slot-scope="scope">
                    <span>{{scope.row.states==0?'未审核':scope.row.states==1?'指导教师审核通过':scope.row.states==2?'院系审核通过':scope.row.states==3?'学校审核通过':scope.row.states==7?'待评审':scope.row.states==8?'评审中':scope.row.states==9?'评审完成待申报':scope.row.states==11?'已申报':scope.row.states==12?'待中期检查':scope.row.states==13?'中期检查通过':scope.row.states==14?'中期检查未通过':scope.row.states==15?'待结项':scope.row.states==16?'结项通过':scope.row.states==17?'结项驳回':scope.row.states==20?'已过期':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="score" label="得分" width="120px">
                <template slot-scope="scope">
                    {{scope.row.states>8?scope.row.score:'未评分'}}
                </template>
            </el-table-column>
            <el-table-column label="项目进度" width="100px">
                <template slot-scope="scope">
                    <el-button type="text" @click="Checkdule(scope.row)" size="mini">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" size="mini">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="totalnum">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <div class="tcation_Popup">
            <el-dialog
                title="项目进度详情"
                :visible.sync="dialogVisible"
                width="1000px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-table 
                    :data="fundList" 
                    style="width: 100%"
                    stripe
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
                >   
                    <el-table-column type="index" width="50" label="#"></el-table-column>
                    <el-table-column prop="name" label="日志名称" width="210px"></el-table-column>
                    <el-table-column prop="attach" label="文件地址">
                        <template slot-scope="scope">
                            <a :href="scope.row.attach" target="_blank" class="aaaaa">{{scope.row.attach}}</a>
                        </template>
                    </el-table-column>
                    <el-table-column prop="creatTime" label="上传时间" width="120px"></el-table-column>
                </el-table>
            </el-dialog>
            <el-dialog
                title="选择专家"
                :visible.sync="dialogVisibleForm"
                width="1000px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-table 
                    :data="judgList" 
                    style="width: 100%"
                    @selection-change="Chooseexpert"
                    stripe
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
                >   
                    <el-table-column type="selection" label="选择" width="55"></el-table-column>
                    <el-table-column prop="name" label="专家名称" ></el-table-column>
                    <el-table-column prop="phoneNumber" label="联系方式"></el-table-column>
                </el-table>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="addmbers()">提交评审</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            departList:[],
            listYear:[],
            dialogVisibleForm:false,
            dialogVisible:false,
            judgList:[],
            value:'',
            depart:'',
            pageSize:10,
            totalnum:0,
            currentPage:1,
            tableData:[],
            multipleSelection:[],
            experList:[],
        }
    },
    methods: {
        initSelectYear() {
            //初始化"检查年份"下拉框
            let year = new Date().getFullYear()
            this.value = year
            this.listYear = [];
            for(let i=0;i<5;i++){
                this.listYear.push({value:(year - i),label:(year - i)+ "年"});
            }
        },
        getapp(){
            this.axios.studentItemlist({
                params:{
                    schoolId:this.$store.state.schId,
                    createTime:this.value
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                    
                    res.data.rows.map(item=>{

                    })
                    
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
            // 查询院系
            this.axios.studentuid({
                params:{
                    uid:this.$store.state.uid
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.departList = res.data.data.yuanxi
                }else{
                    this.departList = []
                }
            }).catch(err=>{

            })
        },
        //查看进度
        Checkdule(rows){
            this.axios.loglist({
                params:{
                    itemId:rows.itmId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.fundList = res.data.rows
                    this.dialogVisible=true
                }else{
                    this.fundList = []
                }
            }).catch(err=>{

            })
        },
        selectable(row,index){
           if(row.states==3){
               return true
           }else{
               return false
           }
        },
        // 选择院系
        departs(val){
            this.axios.studentItemlist({
                params:{
                    schoolId:this.$store.state.schId,
                    createTime:this.value,
                    
                }
            })
        },
        // 全选项目
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //全选专家
        Chooseexpert(val){
            this.experList = val
        },
        //选择专家弹窗
        experts(){
            if(this.multipleSelection.length!=0){
                this.axios.judge({
                    params:{
                        schoolId:this.$store.state.schId
                    }
                }).then(res=>{
                    console.log(res.data)
                    if(res.data!=''){
                        this.judgList = res.data.judge
                        this.dialogVisibleForm = true
                    }else{
                        this.judgList = []
                    }
                }).catch(err=>{

                })
            }else{
                this.$message.error('请选择要提交的项目')
            }
        },
        addmbers(){
            if(this.experList.length!=0){
                var arr = []
                this.multipleSelection.map(item=>{
                    arr.push(item.itmId)
                })
                var array = []
                this.experList.map(item=>{
                    array.push(item.adId)
                })
                this.axios.reviewrefer({
                    itmIds:arr.join(','),
                    uIds:array.join(',')
                }).then(res=>{
                    if(res.data.code==200){
                        this.dialogVisibleForm = false
                        this.getapp()
                        this.$message.success('提交成功')
                    }
                }).catch(err=>{

                })
            }else{
                this.$message.error('请至少选择一位专家在提交')
            }
        },
        //关闭弹窗
        closei(){
            this.dialogVisible = false,
            this.dialogVisibleForm = false
        },
        yearChange(val){
            this.value = val
            this.getapp()
        },
        handleClick(rows){
            this.$router.push({name:'Projectdetails',query:{itemId:rows.itmId,val:2}})
        },
        handleClose(done){

        },
        handleSizeChange(val){
            console.log(`当前页：${val}`)
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.tableData = this.newarrays[val - 1]
        },
    },
    mounted() {
        this.getapp()
    },
    created () {
        this.initSelectYear();
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.tcation_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.tcation_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.tcation_Popup .el-table__body-wrapper{
    min-height: 300px!important;;
    height: 300px!important;
}
.tcation_Popup .el-table__empty-block{
    min-height: 300px!important;;
    height: 300px!important;
}
.tcation_Popup .el-dialog__footer{
    text-align: center;
}
.tcation_Popup .el-textarea__inner{
    resize: none;
    width: 450px;
}
.Projectcation .el-table__body-wrapper{
    min-height: calc(100vh - 334px);
}
.Projectcation .el-table__empty-block{
    min-height: calc(100vh - 334px);
}
</style>